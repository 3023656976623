.banner {
   background-position: center !important;
   width: 100%;
   background-size: cover !important;
   background-repeat: no-repeat !important;
   /* height: calc(100vh - 110px);
   max-height: fit-content; */
   min-height: 600px;
   color: white;
   padding: 24px 7%;
}

.banner-child {
   height: fit-content;
   display: flex;
   align-self: flex-end;
}

.banner-main,
.banner-main span,
.banner-main h1 {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 4.5rem;
   line-height: 5.2rem;
   margin-bottom: 1.5rem;
   word-break: break-word;
}

.banner-main span {
   color: #00adef;
}

.banner-sub {
   font-style: normal;
   font-weight: normal;
   font-size: 1rem;
   line-height: 1.5rem;
   margin-bottom: 3.5rem;
}
