.cat-card {
   background: #ffffff;
   box-shadow: 0px 10px 40px 4px rgba(38, 50, 56, 0.04);
   border-radius: 24px;
   padding: 34px 30px;
   padding-left: 52px;
   column-gap: 2.5rem;
}

.cat-card p {
   font-family: Nunito;
   font-style: normal;
   font-weight: 500;
   font-size: 1.2rem;
   line-height: 1.69rem;
   display: flex;
   align-items: center;
   color: #323232;
   margin: 0;
}

.why-icon {
   width: 53px;
   min-width: 53px;
   height: 53px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.why-detail h5 {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.2rem;
   line-height: 1.6rem;
   font-feature-settings: "liga" off;
   color: #263238;
}

.why-detail p {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 0.8rem;
   line-height: 1.3rem;
   font-feature-settings: "liga" off;
   color: #868686;
}

.depressed-list {
   margin-top: 4rem;
}

.get-started-space {
   position: relative;
   bottom: -35px;
}

@media (max-width: 991px) {
   .depressed-list {
      margin-top: 0;
   }

   .get-started-space {
      position: static;
      padding-bottom: 20px;
   }
}
