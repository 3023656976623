.startup-criteria-card {
   padding: 3rem;
   column-gap: 1.5rem;
   background: #0d0e32;
   border-radius: 24px;
   margin-bottom: 1rem;
}

.startup-criteria-card h5 {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 1.5rem;
   line-height: 1.9rem;
   font-feature-settings: "liga" off;
   color: #ffffff;
}

.startup-criteria-card p {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 1.125rem;
   line-height: 2.25rem;
   color: #ffffff;
   opacity: 0.8;
   margin: 0;
}

.partner-startup-title {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.5rem;
   line-height: 1.6rem;
   text-transform: capitalize;
   color: #2e3192;
}

.partner-startup-icons {
   flex-basis: 52%;
}

.partner-startup-text {
   flex-basis: 40%;
}

@media (max-width: 991px) {
   .partner-startup-icons {
      flex-basis: 100%;
   }
   .partner-startup-text {
      flex-basis: 100%;
   }
}
