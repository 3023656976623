body {
   margin: 0;
   font-family: "Nunito", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
   font-size: 16px;
   scroll-behavior: smooth;
}

@media (max-width: 1024) {
   * {
      font-size: 14px;
   }
}

@media (max-width: 991px) {
   * {
      font-size: 13px;
   }
}

@media (max-width: 700px) {
   * {
      font-size: 12px;
   }
}
