.ring-bullet {
   position: relative;
   height: fit-content;
   width: fit-content;
}

.ring-bullet span {
   display: inline-block;
   border-radius: 50%;
}

.ring-bullet span:last-child {
   border: 1px solid #263238;
   position: absolute;
   bottom: -5%;
   left: -40%;
}
