.program-card {
   min-height: fit-content;
   width: 100%;
   max-width: 510px;
   border-radius: 24px 24px 0px 0px;
   /* overflow-y: hidden; */
   position: relative;
   transition: 0.4s all;
}

.program-image {
   position: relative;
   height: 520px;
   overflow: hidden;
   border-radius: 24px 24px 0px 0px;
}

.program-image > img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: center top;
}

.program-footer {
   background: white;
   padding: 30px 2rem;
   font-family: Lato;
   font-style: normal;
   font-weight: bold;
   font-size: 20px;
   line-height: 24px;
   color: #263238;
   box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
}

.learn-cta {
   background: #00adef;
   border-radius: 0px;
   width: 70%;
   padding: 20px 36px;
   position: absolute;
   bottom: 0;
   text-align: center;
   left: -50%;
   transition: 0.4s all;
   color: white;
   cursor: pointer;
}

/* .program-card:hover {
   border-radius: 24px;
   transition: 0.4s all;
} */

.program-image:hover .learn-cta {
   left: -1px;
   border-radius: 5px;
   transition: 0.4s all;
}

.program-image:hover {
   border-radius: 24px;
}

.program-image:hover + .program-footer {
   /* visibility: collapse; */
   display: none;
}

.program-more {
   position: absolute;
   left: -100%;
   transition: 0.3s all;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   padding: 16px 10%;
   color: white;
}

.program-more .title {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 20px;
   line-height: 26px;
   color: #f9f9fc;
   margin-bottom: 2rem;
}

.program-more .text {
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 18px;
   color: #f9f9fc;
}

.program-image:hover .program-more {
   background: #242679;
   left: 0;
   /* filter: blur(48px); */
   border-radius: 24px;
   height: 100%;
   transition: 0.3s all;
}

.learn-arrow {
   position: absolute;
   right: 30px;
}

.program-deco {
   position: absolute;
   top: 20px;
   right: 20px;
}

.more-float {
   padding: 45px 25px;
   position: absolute;
   width: 800px;
   height: fit-content;
   bottom: -50%;
   background-color: white;
   box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
   z-index: 5;
}

@media (max-width: 991px) {
   .more-float {
      width: 100%;
      bottom: -80%;
   }
}

.more-float .title {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 20px;
   line-height: 26px;
   color: #222831;
}

.more-float .desc {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 27px;
   color: #858585;
}
