.lg-count {
   flex-basis: 27%;
   width: 100%;
   overflow: hidden;
}

.sm-count {
   flex-basis: 19%;
   width: 100%;
   overflow: hidden;
}

.lg-count img,
.sm-count img {
   width: 80%;
   object-fit: contain;
   padding: 0 1rem;
}

.list-num {
   width: 72px;
   min-width: 72px;
   height: 72px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 24px;
   line-height: 31px;
   color: #e27007;
   margin: 0;
}

.line-text {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 1.125rem;
   line-height: 1.75rem;
   font-feature-settings: "liga" off;
   color: #18191f;
   margin: 0;
}

@media (max-width: 992px) {
   .sm-count,
   .lg-count {
      flex-basis: 50%;
   }
}

@media (max-width: 450px) {
   .sm-count,
   .lg-count {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .lg-count img {
      display: none;
   }

   .lg-count > span,
   .sm-count > span {
      justify-content: center;
   }
}
