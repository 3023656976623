.info-card {
   box-shadow: 0px 10px 40px 4px rgba(38, 50, 56, 0.04);
   border-radius: 24px;
   min-height: 471px;
   width: 100%;
   max-width: 536px;
   padding: 70px 9%;
   padding-bottom: 40px;
}

.info-card .title {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 24px;
   line-height: 34px;
   font-feature-settings: "liga" off;
   margin-top: 48px;
}

.info-card .desc {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 30px;
   font-feature-settings: "liga" off;
}

.sector-item {
   background: #ffffff;
   box-shadow: 0px 10px 40px 4px rgba(38, 50, 56, 0.04);
   border-radius: 24px;
   padding: 21px 24px;
   max-width: 273px;
   padding-left: 42px;
   column-gap: 1.7rem;
}

.sector-item p {
   font-family: Nunito;
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 30px;
   color: #263238;
   margin: 0;
}

.sector-item-wrap {
   padding-left: 1rem;
   padding-right: 1rem;
   flex-basis: 20%;
}

@media (max-width: 511px) {
   .sector-item {
      min-width: 100%;
   }

   .sector-item-wrap {
      flex-basis: 100%;
   }
}
