.kv-btn {
  background-color: #00adef;
  border-radius: 8px;
  border: none;
  height: 60px;
  padding: 10px 3rem;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #f8fdff;
}
