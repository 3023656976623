.layout-child {
   min-height: calc(100vh - 60px - 400px);
   max-width: 1933px;
   margin: 0 auto;
}

@media (max-height: 1000px) {
   .layout-child {
      min-height: 500px;
   }
}
