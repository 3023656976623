.kv-footer {
  background: #02031b;
  color: white;
  padding: 5% 6%;
}

.kv-footer ul {
  list-style: none;
  padding: 0;
}

.kv-footer li {
  margin-bottom: 1.5rem;
}

.kv-footer a {
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.mail-to {
  color: rgba(255, 255, 255, 0.8) !important;
}
