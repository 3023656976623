.faq {
   background-color: #02031c;
   min-height: inherit;
   color: white;
   padding: 81px 7%;
}

.contact-links a {
   color: white !important;
   font-size: 1.25rem;
   line-height: 2rem;
   display: flex;
   align-items: center;
   text-align: center;
   opacity: 0.9;
}

.contact-links a:first-child {
   border-right: 1px solid white;
   margin-right: 1rem;
   padding-right: 1rem;
}

.faq-tab .kv-tab {
   border-color: white;
   color: white;
}

.faq-text {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 1.25rem;
   line-height: 2rem;
   color: #ffffff;
   opacity: 0.9;
}

.search-space {
   position: relative;
   height: 46px;
   width: 70%;
   margin: 0 auto;
}

.search-space input {
   width: 100%;
   background: #ffffff80;
   border-radius: 16px;
   border: none;
   height: inherit;
   padding: 10px 46px;
}

.search-space span {
   color: black;
   position: absolute;
   left: 16px;
}

@media (max-width: 991px) {
   .search-space {
      width: 80%;
   }
}

@media (max-width: 767px) {
   .search-space {
      width: 100%;
   }
}
