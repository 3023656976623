.partner-card {
   background: #ffffff;
   border-radius: 8px;
   min-height: 350px;
   position: relative;
   /* display: flex;
   align-items: center;
   justify-content: center; */
   padding: 2rem 2.2rem;
   transition: 0.5s all;
}

.partner-icon {
   position: absolute;
   width: 116px;
   height: 60px;
   top: calc(50% - 24px);
   left: calc(66% - 116px);
   object-fit: contain;
   object-position: center;
   transition: 0.4s all;
}

.partner-card:hover .partner-icon {
   top: 2rem;
   left: 2.2rem;
   width: 42px;
   height: 26px;
   transition: 0.5s all;
}

.partner-card:hover {
   background: #f9f9fb;
   transition: 0.5s all;
}

.partner-card h5 {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.25rem;
   line-height: 1.63rem;
   text-transform: capitalize;
   color: #2e3192;
   margin-top: calc(26px + 1rem);
   margin-bottom: 1rem;
   opacity: 0;
   transition: 0.8s all;
}

.partner-card p {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 24px;
   text-transform: capitalize;
   color: #323232;
   margin-bottom: 0;
   opacity: 0;
   transition: 0.8s all;
}

.partner-card:hover h5 {
   opacity: 1;
   transition: 0.4s all;
}

.partner-card:hover p {
   opacity: 1;
   transition: 0.4s all;
}
