.header {
   padding: 0 7%;
   height: 60px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: sticky;
   top: 0;
   background-color: white;
   z-index: 100;
}

.desk-header-list {
   display: flex;
   align-items: center;
   list-style: none;
   margin: 0;
   padding: 0;
   column-gap: 3rem;
}

.desk-header-list li,
.desk-header-list a {
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 1rem;
   line-height: 1.3rem;
   color: #3e3e3e;
   text-decoration: none;
}

.desk-header-list .log-in {
   color: #2e3192;
   font-weight: bold;
}

.hams {
   display: none;
}

@media (max-width: 1212px) {
   .desk-header-list {
      column-gap: 1.5rem;
   }
}

@media (max-width: 1090px) {
   .desk-header-list {
      position: fixed;
      top: 60px;
      right: -100%;
      display: block;
      transition: 0.55s all;
      width: 100%;
      height: calc(100vh - 60px);
      background-color: #2eabe2;
      padding: 3rem;
      text-align: right;
      z-index: 5;
   }

   .desk-header-list a {
      color: white !important;
      font-size: 3rem;
      /* margin: 2rem 0 !important; */
      font-weight: bold;
   }

   .desk-header-list li {
      margin: 2.5rem 0;
   }

   .desk-header-list .log-in {
      font-size: 3rem;
   }

   .hams {
      display: block;
   }

   .hams span {
      display: block;
      height: 4px;
      width: 40px;
      background-color: black;
      transition: 0.5s all;
   }

   .hams span:nth-child(2) {
      margin: 6px 0;
   }

   .open-side {
      right: 0px !important;
      transition: 0.55s all;
   }

   .hams-open span:nth-child(1) {
      transform: rotate(45deg) translate(14px, 10px);
      transition: 0.5s all;
   }

   .hams-open span:nth-child(2) {
      transform: rotate(-45deg) translateY(7px);
      transition: 0.5s all;
   }

   .hams-open span:nth-child(3) {
      transform: rotate(-45deg);
      transition: 0.5s all;
   }
}
