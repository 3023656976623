.started-card {
   background: #2e3192;
   border-radius: 24px;
   width: 80%;
   margin: 0 auto;
   padding: 30px 10%;
   display: flex;
   align-items: center;
   justify-content: center;
   column-gap: 3rem;
   min-height: 130px;
   row-gap: 1.5rem;
}

.started-card p {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 1.575rem;
   line-height: 3rem;
   color: #ffffff;
   margin: 0;
}
