.blog-tag {
   background: #ffffff;
   border-radius: 5px;
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0.01em;
   padding: 4px 10px;
}

.blog-info {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.5rem;
   line-height: 1.5rem;
   color: #ffffff;
}

.blog-info p {
   margin-right: 12px;
}

.single-blog-section {
   padding: 81px 7%;
   padding-bottom: 40px;
}

.blog-details p,
.blog-details li {
   font-family: Poppins;
   font-style: normal;
   font-weight: normal;
   font-size: 1rem;
   line-height: 2.2rem;
   color: #323232;
}

.blog-details h4,
.blog-details h5,
.blog-details h6 {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 2rem;
   line-height: 3rem;
   color: #2e3192;
}

.content-header {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.5rem;
   line-height: 2rem;
   text-transform: uppercase;
   font-feature-settings: "pnum" on, "lnum" on;
   border-bottom: 1px solid #cbc8c8;
   padding-bottom: 0.6rem;
   width: 10%;
   margin-bottom: 1rem;
   color: rgba(62, 62, 62, 0.6);
}

.content-list {
   list-style: none;
   padding: 0;
}

.content-list li {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.5rem;
   line-height: 2rem;
   font-feature-settings: "pnum" on, "lnum" on;
   color: #3e3e3e;
}

.content-list li a {
   color: #3e3e3e;
   text-decoration: none;
}

.content-list li a:hover {
   color: #00adef;
}

.comment-box {
   display: flex;
   column-gap: 3rem;
   margin-bottom: 2.5rem;
}

.comment-box .image-placeholder,
.comment-box img {
   width: 64px;
   height: 64px;
   border-radius: 50%;
   min-width: 64px;
   background-color: lightgray;
}

.comment-box img {
   object-fit: cover;
   object-position: center top;
}

.comment-box p {
   margin-bottom: 8px;
}

.comment-box .name {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 1.5rem;
   line-height: 1.8rem;
   color: #263238;
}

.comment-box .time {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 1rem;
   line-height: 1.2rem;
   color: #828282;
}

.comment-box .comment {
   font-family: Poppins;
   font-style: normal;
   font-weight: normal;
   font-size: 1rem;
   line-height: 1.4;
   color: #323232;
}

.comment-count {
   font-family: Poppins;
   font-style: normal;
   font-weight: normal;
   font-size: 1.5rem;
   line-height: 2rem;
   color: #333333;
}

.comment-field {
   border: none;
   border-bottom: 1px solid #828282;
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 170%;
   color: #323232;
}

.comment-field:focus {
   outline: none;
}

.comment-field::placeholder {
   color: #32323250;
}

.comment-label {
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 170%;
   color: #32323250;
}

.comment-btn {
   background-color: transparent;
   border: 2px solid #2e3192;
   border-radius: 8px;
   color: #2e3192;
}

.leave-reply-title {
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 40px;
   line-height: 130%;
   text-transform: capitalize;
   color: #323232;
}

.leave-reply-subtitle {
   font-family: Poppins;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 170%;
   color: #323232;
}

.recent-post-title {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 2rem;
   line-height: 2.5rem;
   font-feature-settings: "pnum" on, "lnum" on;
   color: #323232;
}

.post-card img {
   height: 320px;
   width: 100%;
   object-position: center;
   object-fit: cover;
   border-radius: 8px;
   margin-bottom: 10px;
}

.post-card .text {
   font-style: normal;
   font-weight: bold;
   font-size: 1rem;
   line-height: 1.2rem;
   font-feature-settings: "pnum" on, "lnum" on;
   color: #333333;
}

.post-card .title {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 1.5rem;
   line-height: 1.8rem;
   text-align: center;
   font-feature-settings: "pnum" on, "lnum" on;
   color: #000000;
}
