.small-title,
.small-title p {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 1.5rem;
   line-height: 1.9rem;
   color: #2e3192;
   text-transform: capitalize;
}

.big-subtitle,
.big-subtitle p {
   font-style: normal;
   font-weight: bold;
   font-size: 2.5rem;
   line-height: 3.5rem;
   color: #263238;
}

.section-wrap {
   padding: 51px 7%;
   min-height: 600px;
   position: relative;
}
