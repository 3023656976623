.person-card section:first-child {
   background-color: yellow;
   overflow: hidden;
}

.person-card section:nth-child(2) {
   background-color: white;
   padding: 10px 1.2rem;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
}

.person-card {
   box-shadow: 0px 10px 40px 4px rgba(38, 50, 56, 0.04);
   border-radius: 24px 24px 0px 0px;
   overflow: hidden;
}

.person-card .name {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 1.5rem;
   line-height: 1.93rem;
   color: #263238;
   margin-bottom: 8px;
}

.person-card .role {
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 1.125rem;
   line-height: 1.43rem;
   color: #263238;
   margin-bottom: 14px;
}

.person-image {
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: center top;
}
