.accord-header {
   background: #0d0e32;
   border-radius: 24px !important;
   border: none !important;
}

.accord-header h5 {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.56rem;
   line-height: 2.5rem;
   display: flex;
   align-items: center;
   color: #ffffff;
}

.accord-header i {
   color: #00adef;
}

.accord-card {
   background: #0d0e32;
   border-radius: 24px !important;
   padding: 2.5rem 3rem;
}

.accord-body {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 1.125rem;
   line-height: 2rem;
   color: rgba(255, 255, 255, 0.8);
}
