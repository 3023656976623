.testimony-video {
   width: 100%;
   border-radius: 24px 0px;
   object-fit: cover;
   object-position: center;
   max-width: 840px;
}

.play-btn {
   position: absolute;
   top: 20%;
   right: 30%;
}

.testimony-desc {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 2rem;
   line-height: 3rem;
   color: #ffffff;
}

.reviewer-img-space {
   width: 86px;
   min-width: 86px;
   height: 86px;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
}

.reviewer-img-space .first-circle {
   display: block;
   width: 76px;
   height: 76px;
   background-color: #fdfae5;
   border-radius: 50%;
   position: absolute;
   top: 0;
   left: 0;
}

.reviewer-img-space .second-circle {
   display: block;
   width: 76px;
   height: 76px;
   background-color: #d8cbf6;
   border-radius: 50%;
   position: absolute;
   bottom: 0;
   right: 0;
}

.reviewer-img-space .reviewer {
   width: 80px;
   height: 80px;
   border-radius: 50%;
   object-position: center top;
   object-fit: cover;
   position: relative;
   z-index: 2;
}

.reviewer-name {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.4rem;
   line-height: 1.8rem;
   color: #ffffff;
   opacity: 0.8;
   margin: 0;
}

.reviewer-cohort {
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 1rem;
   line-height: 1.31rem;
   color: #ffffff;
   opacity: 0.6;
   margin: 0;
}

.testimony-quote {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 31rem;
   line-height: 31rem;
   text-align: center;
   color: #f1f1f1;
   position: absolute;
   top: 10px;
   left: 45%;
}

@media (max-width: 1024px) {
   .testimony-quote {
      font-size: 21rem;
      line-height: 21rem;
      left: 48%;
   }

   .testimony-desc {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #ffffff;
   }
}

@media (max-width: 991px) {
   .testimony-quote {
      font-size: 11rem;
      line-height: 11rem;
      left: 0;
      top: 0;
   }

   .reviewer-img-space .first-circle {
      display: block;
      width: 70px;
      height: 70px;
   }

   .reviewer-img-space .second-circle {
      display: block;
      width: 70px;
      height: 70px;
   }

   .reviewer-img-space .reviewer {
      width: 74px;
      height: 74px;
   }

   .reviewer-img-space {
      width: 80px;
      min-width: 80px;
   }
}

@media (max-width: 600px) {
   .play-btn {
      top: 10%;
      right: 20%;
   }
}

@media (max-width: 500px) {
   .play-btn {
      top: 10%;
      right: 20%;
      width: 50%;
      object-fit: contain;
      object-position: center;
   }
}
