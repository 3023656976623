.course-card {
   width: 100%;
}

.course-card section:first-child {
   background-position: center;
   background-size: cover;
   padding: 1.55rem 2.3rem;
   border-radius: 24px 24px 0px 0px;
   position: relative;
   min-height: 317px;
   max-height: 413px;
}

.course-card section:nth-child(2) {
   box-shadow: 0px 10px 40px 10px rgba(38, 50, 56, 0.08);
   padding: 1.2rem 1.5rem;
   background-color: white;
   border-radius: 0px 0px 24px 24px;
}

.course-card p {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1rem;
   line-height: 1.3rem;
   letter-spacing: 0.01em;
   margin-bottom: 0;
}

.course-card span {
   display: inline-block;
   padding: 4px 10px;
   font-family: DM Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0.01em;
   color: #1405c1;
   background-color: white;
   border-radius: 5px;
}

.course-card h3 {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 1.5rem;
   line-height: 1.8rem;
   letter-spacing: 0.02em;
   color: #ffffff;
   border-left: 6px solid #00adef;
   padding-left: 0.8rem;
   margin-top: 1rem;
}

.course-card section:first-child > div {
   display: flex;
   align-items: center;
   column-gap: 0.68rem;
   position: absolute;
   bottom: 1.55rem;
   right: 2.3rem;
}
