.blog-carousel-content {
   display: flex;
   justify-content: center;
   align-items: center;
}

.blog-carousel .carousel-indicators {
   bottom: -41px !important;
}

.blog-carousel .carousel-indicators > li {
   background-color: #2e3192 !important;
   border-radius: 6px !important;
   height: 8px !important;
   width: 39px !important;
   border-top: none !important;
   border-bottom: none !important;
}

.blog-carousel > .carousel-control-next,
.blog-carousel > .carousel-control-prev {
   height: fit-content !important;
   width: fit-content !important;
   top: -20%;
   opacity: 1;
}

.blog-carousel > .carousel-control-prev {
   right: 0% !important;
   left: 80% !important;
   /* padding-right: 100px; */
}

.blog-carousel .bi {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 48px;
   min-width: 48px;
   height: 48px;
   background-color: #00adef;
   border-radius: 50%;
}

.blog-tab .kv-tab,
.blog-tab .kv-tab-active {
   min-width: 222px !important;
}

@media (min-width: 1024px) {
   .blog-tab .kv-tab,
   .blog-tab .kv-tab-active {
      min-width: 128px;
   }
}
