.kv-field {
   background: #ffffff;
   border: 1px solid #cccccc;
   box-sizing: border-box;
   border-radius: 8px;
   min-height: 60px;
   width: 100% !important;
   max-width: 350px;
   padding: 18px 32px;
}
